import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import {
    ApprovalNav,
    ResourcesNav,
    AssociateResourcesNav,
    ReferalNav,
    DataRequestNav,
    MemberServicesManagementNav,
    AssociateMembersNav,
    AssociateMembersSubNav,
    NotificationsNav,
    AutomatedServicesInputsNav,
    ProductPricingDiscountsNav,
    SubscriptionsNav,
    MyResourcesNav,
    ReferralNav,
    MyPracticesNav,
    CharityApprovalNav,
    UploadNav,
    HomeNav,
    MyGroupNav,
    MyGroupNavAssociate,
    PMGAdmin,
    PBSAdmin,
    ReferralHomeNav,
} from './NewNavItem';
import Helpers from '../../../utils/helper';
import MetisMenu from 'react-metismenu';
import { connect } from 'react-redux';
import { AssociateMemberForNonprofitStatus, PRACTICE_OWNERSHIP_TYPES, PRACTICE_STATUS_ENUM, USER_ROLES } from '../../../utils/constants';
import { Event, gtmEvent } from '../../Tracking/index';
import {
    ASSOCIATE_MEMBER_RIGHTS_ENUM,
    getMappedAssosiateMemberRights,
} from '../../../utils/associateMembersConstants';
import { LoadUserSummaryFromStorage } from '../../../slices/user-summary/load-user-summary-from-storage';
import LinkComponentWithBadge from '../LinkComponentWithBadge/LinkComponentWithBadge';
import LinkComponentWithNotificationFlag from '../../LinkComponentWithNotificationFlag/LinkComponentWithNotificationFlag';
import { NotificationFlagsCategories } from '../../../models/NotificationFlags/NotificationFlagsCategories';

class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            isPrimaryOwner: false,
            isFirstTimeUser: false,
            menus: [],
            isAnyPracticeApproved: false,
            isAnyActivePracticePending: false,
            isAnyActivePracticeApproved: false,
            practicesNav: [],
            isResourcesEnabled: false,
            isLoading: true,
            shouldRenderChild: false,
            isOtherDataRequestProjectAvailable: false,
            otherDataRequestProjectHighlightedQuestionCount: false,
            isAssociateHaveAccessToSeeReferralHub: false,
        };
        this.token = localStorage.getItem('token');
        this.summary = props.summary;
    }

    componentDidUpdate() {
        if (
            this.props.summary &&
            JSON.stringify(this.props.summary) != JSON.stringify(this.summary)
        ) {
            this.summary = this.props.summary;
            this.updateGroupsAndPractices();
            this.setIsFirstTimeUser();
            this.setState({ shouldRenderChild: !this.state.shouldRenderChild });
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        if (this.summary) {
            this.setIsAdmin();
            this.updateGroupsAndPractices();
            this.setIsFirstTimeUser();
        }

        this.setIsResourcesEnabled();
        this.setState({ isLoading: false });
        this.setAssociateAccessRights();
    }

    isUserHasAccessRight = () => {
        return (
            this.summary.roleId === USER_ROLES.USER && !this.summary.isReferral
        );
    };

    setAssociateAccessRights() {
        if (this.summary.associateMember?.length > 0) {
            this.state.isAssociateHaveAccessToSeeReferralHub =
                this.summary?.associateMember[0]?.practices?.some(
                    (p) =>
                        getMappedAssosiateMemberRights(p.acceptedAccessRights)
                            .referralPartnerExtension
                ) || (getMappedAssosiateMemberRights(this.summary?.associateMember[0]?.nonprofitMapping?.associateMemberAccessRights)
                .referralPartnerExtension && this.summary?.associateMember[0]?.nonprofitMapping?.associateMemberStatus === AssociateMemberForNonprofitStatus.APPROVED)
        }
    }

    updateGroupsAndPractices = () => {
        let isAnyPracticeApproved = false;
        let isAnyActivePracticePending = false;
        if (this.summary.practices) {
            const approvedPractices = this.summary.practices.filter(
                (x) => x.status == PRACTICE_STATUS_ENUM.Approved
            );
            isAnyPracticeApproved = approvedPractices.length > 0;
            this.setState({
                isAnyPracticeApproved: isAnyPracticeApproved,
            });

            const pendingPractices = this.summary.practices.filter(
                (x) => 
                    x.status == PRACTICE_STATUS_ENUM.Pending && 
                    x.practiceOwnershipType != PRACTICE_OWNERSHIP_TYPES.FORMER
            );
            isAnyActivePracticePending = pendingPractices.length > 0;
            this.setState({
                isAnyActivePracticePending: isAnyActivePracticePending,
            });

            const approvedActivePractices = this.summary.practices.filter(
                (x) => 
                    x.status == PRACTICE_STATUS_ENUM.Approved && 
                    x.practiceOwnershipType != PRACTICE_OWNERSHIP_TYPES.FORMER
            );
            const isAnyActivePracticeApproved =
                approvedActivePractices.length > 0;
            this.setState({
                isAnyActivePracticeApproved: isAnyActivePracticeApproved,
            });

            this.setIsPrimaryOwner();

            this.setState({
                practicesNav: this.createPracticesNavigation(
                    this.summary.practices
                ),
            });
        }

        this.setState({
            isOtherDataRequestProjectAvailable:
                this.summary.isOtherDataRequestProjectAvailable,
            otherDataRequestProjectHighlightedQuestionCount:
                this.summary.otherDataRequestProjectHighlightedQuestionCount,
        });

        this.uniqueGroups = [];
        if (this.summary.groups) {
            this.uniqueGroups = Array.from(
                new Set(this.summary.groups.map((g) => g.groupId))
            ).map((id) => {
                const grp = this.summary.groups.find((g) => g.groupId == id);
                return {
                    groupId: id,
                    groupName: grp.groupName,
                    notificationFlagPrefix: `${NotificationFlagsCategories.PmgCode}.${grp.groupId}`,
                };
            });
        }
        if (
            this.isOwnerOrAssociateAndHasAccessRight(
                ASSOCIATE_MEMBER_RIGHTS_ENUM.KnowledgeBase
            ) ||
            this.isUserHasAccessRight()
        ) {
            const pmkb = {
                groupName: 'Practice Management Knowledge Library',
                to: `/knowledge-library`,
                isCustom: true,
                notificationFlagPrefix: NotificationFlagsCategories.KnowledgeLibraryCode,
            };
            this.uniqueGroups.unshift(pmkb);
        }
        if (
            this.isOwnerOrAssociateAndHasAccessRight(
                ASSOCIATE_MEMBER_RIGHTS_ENUM.POC
            ) ||
            isAnyPracticeApproved ||
            isAnyActivePracticePending
        ) {
            const poc = {
                groupName: 'The IPOC',
                to: `/ipoc`,
                isCustom: true,
                notificationFlagPrefix: NotificationFlagsCategories.PocCode,
            };
            this.uniqueGroups.unshift(poc);
        }

        this.myresourcesList =
            this.summary.roleId === USER_ROLES.PRACTICE_SECONDARY_USER
                ? AssociateResourcesNav
                : ResourcesNav;
    };

    isOwnerOrAssociateAndHasAccessRight = (accessRight) => {
        return (
            this.summary.roleId === USER_ROLES.PRACTICE_OWNER ||
            (this.summary.roleId === USER_ROLES.PRACTICE_SECONDARY_USER &&
                this.summary.associateMember &&
                this.summary.associateMember.some((x) =>
                    x.practices.some((p) =>
                        p.acceptedAccessRights.includes(accessRight)
                    )
                ))
        );
    };

    setIsResourcesEnabled = () => {
        let isResourcesEnabled = Helpers.isOwner();
        this.setState({ isResourcesEnabled });
    };

    setIsFirstTimeUser = () => {
        let isFirstTime =
            this.summary &&
            this.summary.practices?.length === 0 &&
            !this.summary.hasReferralRequest &&
            this.summary.roleId !== USER_ROLES.PRACTICE_SECONDARY_USER;
        this.setState({ isFirstTimeUser: isFirstTime });
    };

    setIsPrimaryOwner = () => {
        let isPrimaryOwner = false;
        if (this.summary.roleId === USER_ROLES.PRACTICE_OWNER) {
            isPrimaryOwner = true;
        }
        this.setState({ isPrimaryOwner });
    };

    setIsAdmin = () => {
        if (
            this.summary &&
            this.summary.roleId &&
            this.summary.roleId === USER_ROLES.ADMIN
        ) {
            this.setState({ isAdmin: true });
        } else {
            this.setState({ isAdmin: false });
        }
    };

    createPracticesNavigation = (practices) => {
        const practiceNav = [];

        const nav = {
            icon: 'pe-7s-rocket',
            label: 'My Practices',
            to: `/practices`,
            isVisible: true,
            isDisabled: false,
            perCode: [],
        };
        practiceNav.push(nav);

        for (let practice of practices) {
            const nav = {
                icon: 'pe-7s-home',
                label: practice.name,
                to: `/practice/${practice.id}`,
                isVisible: true,
                isDisabled: false,
                perCode: [],
                notificationFlagCode: `${NotificationFlagsCategories.PracticesCode}.${practice.id}`,
            };
            practiceNav.push(nav);
        }
        return practiceNav;
    };

    getPracticeNavItem = (practice, index) => {
        let content = [
            {
                icon: 'pe-7s-home',
                label: practice.name,
                to: `/practice/${practice.id}`,
                isVisible: true,
                isDisabled: false,
                perCode: [],
                notificationFlagCode: `${NotificationFlagsCategories.PracticesCode}.${practice.id}`,
            },
        ];
        return (
            <div className="flex-container">
                <MetisMenu
                    key={index}
                    content={content}
                    activeLinkFromLocation
                    className={
                        practice.id
                            ? 'vertical-nav-menu'
                            : 'vertical-nav-menu custom'
                    }
                    style={{ marginLeft: '-15px' }}
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    onSelected={
                        (() => Event('MenuBar', practice.name, 'LEFT_MENU'),
                        gtmEvent('MenuBar'))
                    }
                    LinkComponent={(params) => {
                        return (
                            <LinkComponentWithNotificationFlag
                                {...params}
                                icon="pe-7s-share"
                                flagCode={
                                    content[0].notificationFlagCode
                                }></LinkComponentWithNotificationFlag>
                        );
                    }}
                />
            </div>
        );
    };

    getCharityAdminCharityNavItem = (charity) => {
        return (
            <MetisMenu
                content={[
                    {
                        icon: 'pe-7s-network',
                        label: 'My Nonprofit',
                        isVisible: true,
                        isDisabled: false,
                        to: `/charities/${charity.id}/edit`,
                        perCode: [],
                    },
                ]}
                activeLinkFromLocation
                className={
                    charity
                        ? 'app-sidebar__heading vertical-nav-menu'
                        : 'app-sidebar__heading vertical-nav-menu custom'
                }
                style={{ marginLeft: '-10px' }}
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
                onSelected={
                    (() => Event('MenuBar', 'My Charity', 'LEFT_MENU'),
                    gtmEvent('My Charity'),
                    () => {
                        localStorage.setItem('idforCharitybyId', charity.id);
                        localStorage.setItem('idforCharityproposalbyId', '-');
                    })
                }
                toggle="false"
            />
        );
    };

    getCharityNavItem = (charity, index) => {
        let content = [
            {
                icon: 'pe-7s-rocket',
                label: charity.charityName,
                to: `/charityedit/${charity.id}`,
                isVisible: true,
                isDisabled: false,
                perCode: [],
            },
        ];
        return (
            <MetisMenu
                key={index}
                content={content}
                activeLinkFromLocation
                className={
                    charity.id
                        ? 'vertical-nav-menu'
                        : 'vertical-nav-menu custom'
                }
                style={{ marginLeft: '-15px' }}
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
                onSelected={
                    (() => Event('MenuBar', charity.charityName, 'LEFT_MENU'),
                    gtmEvent('MenuBar'),
                    () => {
                        localStorage.setItem('idforCharitybyId', charity.id);
                        localStorage.setItem('idforCharityproposalbyId', '-');
                    })
                }
            />
        );
    };

    getMyResourcesItem = (res, index, className) => {
        let iconName = '';
        if (res.label === 'Sample Reports') {
            iconName = 'pe-7s-graph3';
        } else if (res.label === 'Valuations Demos') {
            iconName = 'pe-7s-display2';
        } else if (res.label === 'Knowledge Base') {
            iconName = 'pe-7s-note2';
        } else if (res.label === 'PMG') {
            iconName = 'pe-7s-users';
        } else if (res.label === 'PMG1') {
            iconName = 'pe-7s-users';
        } else {
            iconName = 'pe-7s-network';
        }
        let content = [
            {
                icon: iconName,
                label: res.label,
                to: res.to,
            },
        ];
        return (
            <MetisMenu
                key={index}
                content={content}
                activeLinkFromLocation
                className={className}
                style={{ marginLeft: '-15px' }}
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
                onSelected={
                    (() => Event('MenuBar', res.label, 'LEFT_MENU'),
                    gtmEvent('MenuBar'))
                }
            />
        );
    };

    getGroupNavItem = (group, index) => {
        const loggedInSummary = JSON.parse(
            localStorage.getItem('loggedInUserSummary')
        );
        let hasApprovedPractice =
            loggedInSummary.practices?.filter(
                (x) => x.status == PRACTICE_STATUS_ENUM.Approved
            ).length > 0;
        let content = [
            {
                icon: 'pe-7s-users',
                label: Helpers.formatGroupName(group.groupName),
                to: group.to
                    ? group.to
                    : `/practice-management-pmg/${group.groupId}`,
                isVisible: true,
                isDisabled: false,
                perCode: [],
                notificationFlagPrefix: group.notificationFlagPrefix,
            },
        ];

        return (
            <MetisMenu
                key={index}
                content={content}
                activeLinkFromLocation
                className={
                    group.groupId || group.isCustom
                        ? hasApprovedPractice || this.summary.isReferral
                            ? 'vertical-nav-menu'
                            : ' vertical-nav-menu disabled'
                        : hasApprovedPractice || this.summary.isReferral
                        ? 'vertical-nav-menu custom'
                        : 'vertical-nav-menu custom disabled'
                }
                style={{ marginLeft: '-15px' }}
                classNameStateIcon="pe-7s-angle-down"
                onSelected={
                    (() => Event('MenuBar', group.groupName, 'LEFT_MENU'),
                    gtmEvent('MenuBar'),
                    () => {})
                }
                LinkComponent={(params) => (
                    <LinkComponentWithNotificationFlag
                        {...params}
                        icon="pe-7s-users"
                        flagCode={
                            content[0].notificationFlagPrefix
                        }></LinkComponentWithNotificationFlag>
                )}
            />
        );
    };

    isReferralOrCharityReferral() {
        return (
            this.summary.isReferral &&
            (!this.summary.practices || this.summary.practices.length === 0) &&
            ((this.summary.roleId === USER_ROLES.CHARITY_ADMIN &&
                this.summary.isReferral) ||
                this.summary.roleId === USER_ROLES.USER)
        );
    }

    render() {
        return this.state.isLoading ? (
            ''
        ) : (
            <Fragment>
                <div id="left-menu"></div>
                {
                    /// Practice Owner menu Item
                    this.summary.roleId === USER_ROLES.PRACTICE_OWNER ? (
                        <div>
                            <MetisMenu
                                content={HomeNav}
                                activeLinkFromLocation
                                className={
                                    this.state.isAnyPracticeApproved
                                        ? 'app-sidebar__heading vertical-nav-menu'
                                        : 'app-sidebar__heading vertical-nav-menu custom'
                                }
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event('MenuBar', 'Home', 'LEFT_MENU'),
                                    gtmEvent('Home'))
                                }
                                toggle="false"
                            />

                            <MetisMenu
                                content={AssociateMembersSubNav}
                                activeLinkFromLocation
                                className="app-sidebar__heading vertical-nav-menu"
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event(
                                            'MenuBar',
                                            'My Account',
                                            'LEFT_MENU'
                                        ),
                                    gtmEvent('My Account'))
                                }
                                toggle="false"
                                LinkComponent={() => (
                                    <LinkComponentWithNotificationFlag
                                        to={AssociateMembersSubNav[0].to}
                                        label={AssociateMembersSubNav[0].label}
                                        icon={AssociateMembersSubNav[0].icon}
                                        flagCode={NotificationFlagsCategories.AccountCode}></LinkComponentWithNotificationFlag>
                                )}
                            />

                            <MetisMenu
                                content={NotificationsNav}
                                activeLinkFromLocation
                                className={'vertical-nav-menu'}
                                style={{ marginLeft: '-15px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event(
                                            'MenuBar',
                                            'Notifications',
                                            'LEFT_MENU'
                                        ),
                                    gtmEvent('MenuBar'))
                                }
                            />
                            <MetisMenu
                                content={AssociateMembersNav}
                                activeLinkFromLocation
                                className={
                                    this.state.isAnyPracticeApproved
                                        ? 'app-sidebar__heading vertical-nav-menu'
                                        : 'app-sidebar__heading vertical-nav-menu custom'
                                }
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event(
                                            'MenuBar',
                                            'My Affiliated Members',
                                            'LEFT_MENU'
                                        ),
                                    gtmEvent('My Account'))
                                }
                                toggle="false"
                            />
                            <MetisMenu
                                content={MyPracticesNav}
                                activeLinkFromLocation
                                className="app-sidebar__heading vertical-nav-menu"
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event(
                                            'MenuBar',
                                            'My Practices',
                                            'LEFT_MENU'
                                        ),
                                    gtmEvent('My Account'))
                                }
                                toggle="false"
                            />
                            {this.summary.practices?.map((practice, index) =>
                                this.getPracticeNavItem(practice, index)
                            )}
                            <MetisMenu
                                content={MyGroupNav}
                                activeLinkFromLocation
                                className={
                                    this.state.isAnyPracticeApproved
                                        ? 'app-sidebar__heading vertical-nav-menu'
                                        : 'app-sidebar__heading vertical-nav-menu custom '
                                }
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event(
                                            'MenuBar',
                                            'My Groups & Resources',
                                            'LEFT_MENU'
                                        ),
                                    gtmEvent('My Account'))
                                }
                                toggle="false"
                            />
                            {this.uniqueGroups.map((group, index) =>
                                this.getGroupNavItem(group, index)
                            )}
                            <MetisMenu
                                content={ReferalNav}
                                activeLinkFromLocation
                                className={
                                    this.state.isAnyPracticeApproved ||
                                    this.summary.isReferral
                                        ? 'app-sidebar__heading vertical-nav-menu'
                                        : 'app-sidebar__heading vertical-nav-menu custom'
                                }
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event(
                                            'MenuBar',
                                            'My Referrals',
                                            'LEFT_MENU'
                                        ),
                                    gtmEvent('My Account'))
                                }
                                toggle="false"
                                LinkComponent={(params) => (
                                    <LinkComponentWithNotificationFlag
                                        {...params}
                                        icon="pe-7s-share"
                                        flagCode={
                                            NotificationFlagsCategories.ReferralHubCode
                                        }></LinkComponentWithNotificationFlag>
                                )}
                            />
                            {this.state.isOtherDataRequestProjectAvailable ? (
                                <MetisMenu
                                    content={DataRequestNav}
                                    activeLinkFromLocation
                                    LinkComponent={(params) => {
                                        return (
                                            <LinkComponentWithBadge
                                                {...params}
                                                icon="pe-7s-share"
                                                badgeCount={
                                                    this.state
                                                        .otherDataRequestProjectHighlightedQuestionCount
                                                }></LinkComponentWithBadge>
                                        );
                                    }}
                                    className={
                                        'app-sidebar__heading vertical-nav-menu'
                                    }
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'Data Request',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('Data Request'))
                                    }
                                    toggle="false"></MetisMenu>
                            ) : (
                                []
                            )}
                        </div>
                    ) : (
                        <></>
                    )
                }

                {this.summary.roleId === USER_ROLES.PRACTICE_SECONDARY_USER ? (
                    <div>
                        {this.summary.roleId ===
                        USER_ROLES.PRACTICE_SECONDARY_USER ? (
                            <MetisMenu
                                content={HomeNav}
                                activeLinkFromLocation
                                className={
                                    this.state.isAnyPracticeApproved
                                        ? 'app-sidebar__heading vertical-nav-menu'
                                        : 'app-sidebar__heading vertical-nav-menu custom'
                                }
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event('MenuBar', 'Home', 'LEFT_MENU'),
                                    gtmEvent('Home'))
                                }
                                toggle="false"
                            />
                        ) : (
                            []
                        )}

                        <MetisMenu
                            content={AssociateMembersSubNav}
                            activeLinkFromLocation
                            className="app-sidebar__heading vertical-nav-menu"
                            style={{ marginLeft: '-10px' }}
                            iconNamePrefix=""
                            classNameStateIcon="pe-7s-angle-down"
                            onSelected={
                                (() =>
                                    Event('MenuBar', 'My Account', 'LEFT_MENU'),
                                gtmEvent('My Account'))
                            }
                            toggle="false"
                        />

                        <MetisMenu
                            content={NotificationsNav}
                            activeLinkFromLocation
                            className={'vertical-nav-menu'}
                            style={{ marginLeft: '-15px' }}
                            iconNamePrefix=""
                            classNameStateIcon="pe-7s-angle-down"
                            onSelected={
                                (() =>
                                    Event(
                                        'MenuBar',
                                        'Notifications',
                                        'LEFT_MENU'
                                    ),
                                gtmEvent('MenuBar'))
                            }
                        />
                        {this.summary.associateMember[0]?.nonprofitMapping?.associateMemberStatus === AssociateMemberForNonprofitStatus.APPROVED && !this.summary.associateMember[0]?.nonprofitMapping?.isMemberBelongToFormerNonprofitAdmin ? (
                                <MetisMenu
                                    content={[
                                        {
                                            icon: 'pe-7s-network',
                                            label: 'My Nonprofit',
                                            isVisible: true,
                                            isDisabled: false,
                                            to: `/charities/${this.summary.associateMember[0].nonprofitMapping.nonprofitId}/edit`,
                                            perCode: [],
                                        },
                                    ]}
                                    activeLinkFromLocation
                                    className={
                                        'app-sidebar__heading vertical-nav-menu'
                                    }
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() => Event('MenuBar', 'My Charity', 'LEFT_MENU'),
                                            gtmEvent('My Charity'),
                                            () => {
                                                localStorage.setItem('idforCharitybyId', this.summary.associateMember[0].nonprofitMapping.nonprofitId);
                                                localStorage.setItem('idforCharityproposalbyId', '-');
                                            })
                                    }
                                    toggle="false"
                                />
                            ) : (
                                <>
                                    <MetisMenu
                                        content={MyPracticesNav}
                                        activeLinkFromLocation
                                        className="app-sidebar__heading vertical-nav-menu"
                                        style={{ marginLeft: '-10px' }}
                                        iconNamePrefix=""
                                        classNameStateIcon="pe-7s-angle-down"
                                        onSelected={
                                            (() =>
                                                Event(
                                                    'MenuBar',
                                                    'My Practices',
                                                    'LEFT_MENU'
                                                ),
                                                gtmEvent('My Account'))
                                        }
                                        toggle="false"
                                    />
                                    {Array.isArray(this.summary.practices) ? (
                                        <>
                                            {this.summary.practices.map((practice, index) =>
                                                this.getPracticeNavItem(practice, index)
                                            )}
                                        </>
                                    ) : (
                                        []
                                    )}
                                </>)
                        }

                        {this.summary.roleId ===
                        USER_ROLES.PRACTICE_SECONDARY_USER ? (
                            <>
                                <MetisMenu
                                    content={MyGroupNavAssociate}
                                    className={
                                        this.state.isAnyPracticeApproved
                                            ? 'app-sidebar__heading vertical-nav-menu hidden-background'
                                            : 'app-sidebar__heading vertical-nav-menu custom hidden-background'
                                    }
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'My Groups & Resources',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('My Account'))
                                    }
                                    toggle="false"
                                />
                                {this.uniqueGroups.map((group, index) =>
                                    this.getGroupNavItem(group, index)
                                )}
                            </>
                        ) : (
                            []
                        )}
                        {this.state.isAssociateHaveAccessToSeeReferralHub ? (
                            <MetisMenu
                                content={ReferalNav}
                                activeLinkFromLocation
                                className={
                                    this.state.isAnyPracticeApproved || this.state.isAssociateHaveAccessToSeeReferralHub
                                        ? 'app-sidebar__heading vertical-nav-menu'
                                        : 'app-sidebar__heading vertical-nav-menu custom'
                                }
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event(
                                            'MenuBar',
                                            'My Referrals',
                                            'LEFT_MENU'
                                        ),
                                    gtmEvent('My Account'))
                                }
                                toggle="false"
                                LinkComponent={(params) => (
                                    <LinkComponentWithNotificationFlag
                                        {...params}
                                        icon="pe-7s-share"
                                        flagCode={
                                            NotificationFlagsCategories.ReferralHubCode
                                        }></LinkComponentWithNotificationFlag>
                                )}
                            />
                        ) : (
                            []
                        )}
                    </div>
                ) : (
                    <></>
                )}

                {this.summary.roleId === USER_ROLES.USER ? (
                    <div>
                        {this.state.isAdmin ? (
                            <MetisMenu
                                content={ApprovalNav}
                                activeLinkFromLocation
                                className="app-sidebar__heading vertical-nav-menu"
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event(
                                            'MenuBar',
                                            'Approval Queues',
                                            'LEFT_MENU'
                                        ),
                                    gtmEvent('MenuBar'))
                                }
                                toggle="false"
                            />
                        ) : (
                            <></>
                        )}
                        {this.state.isAdmin ? (
                            <MetisMenu
                                content={CharityApprovalNav}
                                activeLinkFromLocation
                                className="app-sidebar__heading vertical-nav-menu"
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event(
                                            'MenuBar',
                                            'charity approval',
                                            'LEFT_MENU'
                                        ),
                                    gtmEvent('MenuBar'))
                                }
                                toggle="false"
                            />
                        ) : (
                            ''
                        )}
                        {this.summary.roleId === USER_ROLES.PRACTICE_OWNER && (
                            <Fragment>
                                <MetisMenu
                                    content={ReferralNav}
                                    activeLinkFromLocation
                                    className="vertical-nav-menu app-sidebar__heading"
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'Referral',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('MenuBar'))
                                    }
                                    toggle="false"
                                />
                                <MetisMenu
                                    content={ReferalNav}
                                    activeLinkFromLocation
                                    className={
                                        (this.summary.roleId ===
                                            USER_ROLES.PRACTICE_OWNER &&
                                            this.state.isAnyPracticeApproved) ||
                                        this.summary.isReferral
                                            ? 'vertical-nav-menu'
                                            : 'vertical-nav-menu custom'
                                    }
                                    style={{ marginLeft: '-15px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'My Referrals',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('MenuBar'))
                                    }
                                    LinkComponent={(params) => (
                                        <LinkComponentWithNotificationFlag
                                            {...params}
                                            icon="pe-7s-share"
                                            flagCode={
                                                NotificationFlagsCategories.ReferralHubCode
                                            }></LinkComponentWithNotificationFlag>
                                    )}
                                />
                            </Fragment>
                        )}
                        {this.state.isAdmin ? (
                            <MetisMenu
                                content={MyResourcesNav}
                                activeLinkFromLocation
                                className="vertical-nav-menu app-sidebar__heading"
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event(
                                            'MenuBar',
                                            'User Accounts',
                                            'LEFT_MENU'
                                        ),
                                    gtmEvent('MenuBar'))
                                }
                                toggle="false"
                            />
                        ) : (
                            <></>
                        )}
                        {!this.state.isResourcesEnabled ||
                        (!this.state.isAdmin &&
                            !this.state.isAnyPracticeApproved) ? (
                            <div>
                                {!this.isReferralOrCharityReferral() ? (
                                    <MetisMenu
                                        content={HomeNav}
                                        activeLinkFromLocation
                                        className={
                                            this.state.isAnyPracticeApproved ||
                                            this.summary.isReferral ||
                                            this.state.isFirstTimeUser
                                                ? 'app-sidebar__heading vertical-nav-menu'
                                                : 'app-sidebar__heading vertical-nav-menu custom'
                                        }
                                        style={{ marginLeft: '-10px' }}
                                        iconNamePrefix=""
                                        classNameStateIcon="pe-7s-angle-down"
                                        onSelected={
                                            (() =>
                                                Event(
                                                    'MenuBar',
                                                    'Dashboard',
                                                    'LEFT_MENU'
                                                ),
                                            gtmEvent('MenuBar'))
                                        }
                                        toggle="false"
                                    />
                                ) : (
                                    []
                                )}
                                <MetisMenu
                                    content={AssociateMembersSubNav}
                                    activeLinkFromLocation
                                    className="app-sidebar__heading vertical-nav-menu"
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'My Account',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('My Account'))
                                    }
                                    toggle="false"
                                />

                                {this.state.isAnyPracticeApproved ||
                                this.summary.isReferral ? (
                                    <MetisMenu
                                        content={NotificationsNav}
                                        activeLinkFromLocation
                                        className={'vertical-nav-menu'}
                                        style={{ marginLeft: '-15px' }}
                                        iconNamePrefix=""
                                        classNameStateIcon="pe-7s-angle-down"
                                        onSelected={
                                            (() =>
                                                Event(
                                                    'MenuBar',
                                                    'Notifications',
                                                    'LEFT_MENU'
                                                ),
                                            gtmEvent('MenuBar'))
                                        }
                                    />
                                ) : (
                                    []
                                )}
                                {this.state.isAnyActivePracticePending ? (
                                    <MetisMenu
                                        content={AssociateMembersNav}
                                        activeLinkFromLocation
                                        className={
                                            this.state
                                                .isAnyActivePracticeApproved
                                                ? 'app-sidebar__heading vertical-nav-menu'
                                                : 'app-sidebar__heading vertical-nav-menu custom'
                                        }
                                        style={{ marginLeft: '-10px' }}
                                        iconNamePrefix=""
                                        classNameStateIcon="pe-7s-angle-down"
                                        onSelected={
                                            (() =>
                                                Event(
                                                    'MenuBar',
                                                    'My Affiliated Members',
                                                    'LEFT_MENU'
                                                ),
                                            gtmEvent('My Account'))
                                        }
                                        toggle="false"
                                    />
                                ) : (
                                    []
                                )}
                                {this.state.isFirstTimeUser ? (
                                    []
                                ) : (
                                    <>
                                        {!this.summary.hasReferralRequest &&
                                        !this.summary.isReferral ? (
                                            <>
                                                <MetisMenu
                                                    content={MyPracticesNav}
                                                    activeLinkFromLocation
                                                    className="app-sidebar__heading vertical-nav-menu"
                                                    style={{
                                                        marginLeft: '-10px',
                                                    }}
                                                    iconNamePrefix=""
                                                    classNameStateIcon="pe-7s-angle-down"
                                                    onSelected={
                                                        (() =>
                                                            Event(
                                                                'MenuBar',
                                                                'My Practices',
                                                                'LEFT_MENU'
                                                            ),
                                                        gtmEvent('My Account'))
                                                    }
                                                    toggle="false"
                                                />
                                                {this.summary.practices?.map(
                                                    (practice, index) =>
                                                        this.getPracticeNavItem(
                                                            practice,
                                                            index
                                                        )
                                                )}
                                            </>
                                        ) : (
                                            []
                                        )}
                                        {this.summary.roleId !==
                                        USER_ROLES.USER ? (
                                            <MetisMenu
                                                content={MyGroupNav}
                                                activeLinkFromLocation
                                                className={
                                                    'app-sidebar__heading vertical-nav-menu custom'
                                                }
                                                style={{ marginLeft: '-10px' }}
                                                iconNamePrefix=""
                                                classNameStateIcon="pe-7s-angle-down"
                                                onSelected={
                                                    (() =>
                                                        Event(
                                                            'MenuBar',
                                                            'My Groups & Resources',
                                                            'LEFT_MENU'
                                                        ),
                                                    gtmEvent('My Account'))
                                                }
                                                toggle="false"
                                            />
                                        ) : (
                                            []
                                        )}
                                        {this.summary.roleId !== USER_ROLES.USER
                                            ? this.uniqueGroups.map(
                                                  (group, index) =>
                                                      this.getGroupNavItem(
                                                          group,
                                                          index
                                                      )
                                              )
                                            : []}
                                        <MetisMenu
                                            content={ReferalNav}
                                            activeLinkFromLocation
                                            className={
                                                this.state
                                                    .isAnyPracticeApproved ||
                                                this.summary.isReferral
                                                    ? 'app-sidebar__heading vertical-nav-menu'
                                                    : 'app-sidebar__heading vertical-nav-menu custom'
                                            }
                                            style={{ marginLeft: '-10px' }}
                                            iconNamePrefix=""
                                            classNameStateIcon="pe-7s-angle-down"
                                            onSelected={
                                                (() =>
                                                    Event(
                                                        'MenuBar',
                                                        'My Referrals',
                                                        'LEFT_MENU'
                                                    ),
                                                gtmEvent('My Account'))
                                            }
                                            toggle="false"
                                        />
                                    </>
                                )}
                            </div>
                        ) : (
                            <div>
                                {this.myresourcesList.map((res, index) =>
                                    this.getMyResourcesItem(
                                        res,
                                        index,
                                        'vertical-nav-menu'
                                    )
                                )}
                            </div>
                        )}
                        {this.state.isAdmin ? (
                            <>
                                <MetisMenu
                                    content={AssociateMembersNav}
                                    activeLinkFromLocation
                                    className="vertical-nav-menu app-sidebar__heading"
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'User Accounts',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('MenuBar'))
                                    }
                                    toggle="false"
                                />
                                <MetisMenu
                                    content={AssociateMembersSubNav}
                                    activeLinkFromLocation
                                    className="vertical-nav-menu"
                                    style={{ marginLeft: '-15px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'Members Profile',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('MenuBar'))
                                    }
                                    toggle="false"
                                />
                            </>
                        ) : (
                            <></>
                        )}
                        {this.state.isAdmin && (
                            <Fragment>
                                <MetisMenu
                                    content={AutomatedServicesInputsNav}
                                    activeLinkFromLocation
                                    className="app-sidebar__heading vertical-nav-menu"
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'Automated Services Inputs',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('MenuBar'))
                                    }
                                    toggle="false"
                                />
                                <MetisMenu
                                    content={ProductPricingDiscountsNav}
                                    activeLinkFromLocation
                                    className="app-sidebar__heading vertical-nav-menu"
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'Product Pricing & Discounts',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('MenuBar'))
                                    }
                                    toggle="false"
                                />

                                <MetisMenu
                                    content={MemberServicesManagementNav}
                                    activeLinkFromLocation
                                    className="app-sidebar__heading vertical-nav-menu"
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'Valuation Report Input Forms',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('MenuBar'))
                                    }
                                    toggle="false"
                                />

                                <MetisMenu
                                    content={SubscriptionsNav}
                                    activeLinkFromLocation
                                    className="app-sidebar__heading vertical-nav-menu"
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'Subscriptions',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('MenuBar'))
                                    }
                                    toggle="false"
                                />
                                <MetisMenu
                                    content={UploadNav}
                                    activeLinkFromLocation
                                    className="app-sidebar__heading vertical-nav-menu"
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'UploadChart',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('MenuBar'))
                                    }
                                    toggle="false"
                                />
                                <MetisMenu
                                    content={PMGAdmin}
                                    activeLinkFromLocation
                                    className="app-sidebar__heading vertical-nav-menu"
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'PMGAdmin',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('MenuBar'))
                                    }
                                    toggle="false"
                                />
                                <MetisMenu
                                    content={PBSAdmin}
                                    activeLinkFromLocation
                                    className="app-sidebar__heading vertical-nav-menu"
                                    style={{ marginLeft: '-10px' }}
                                    iconNamePrefix=""
                                    classNameStateIcon="pe-7s-angle-down"
                                    onSelected={
                                        (() =>
                                            Event(
                                                'MenuBar',
                                                'Practice Brokerage Service Admin',
                                                'LEFT_MENU'
                                            ),
                                        gtmEvent('MenuBar'))
                                    }
                                    toggle="false"
                                />
                            </Fragment>
                        )}
                    </div>
                ) : (
                    ''
                )}

                {this.summary.roleId === USER_ROLES.CHARITY_ADMIN ? (
                    <div>
                        <MetisMenu
                            content={AssociateMembersSubNav}
                            activeLinkFromLocation
                            className="app-sidebar__heading vertical-nav-menu"
                            style={{ marginLeft: '-10px' }}
                            iconNamePrefix=""
                            classNameStateIcon="pe-7s-angle-down"
                            onSelected={
                                (() =>
                                    Event('MenuBar', 'My Account', 'LEFT_MENU'),
                                gtmEvent('My Account'))
                            }
                            toggle="false"
                        />

                        <MetisMenu
                            content={NotificationsNav}
                            activeLinkFromLocation
                            className={'vertical-nav-menu'}
                            style={{ marginLeft: '-15px' }}
                            iconNamePrefix=""
                            classNameStateIcon="pe-7s-angle-down"
                            onSelected={
                                (() =>
                                    Event(
                                        'MenuBar',
                                        'Notifications',
                                        'LEFT_MENU'
                                    ),
                                gtmEvent('MenuBar'))
                            }
                        />

                        {this.summary.charities &&
                            this.summary.charities?.map((charity, index) =>
                                this.getCharityAdminCharityNavItem(charity)
                            )}

                        <MetisMenu
                            content={AssociateMembersNav}
                            activeLinkFromLocation
                            className={'app-sidebar__heading vertical-nav-menu'}
                            style={{ marginLeft: '-10px' }}
                            iconNamePrefix=""
                            classNameStateIcon="pe-7s-angle-down"
                            onSelected={
                                (() =>
                                    Event(
                                        'MenuBar',
                                        'My Affiliated Members',
                                        'LEFT_MENU'
                                    ),
                                gtmEvent('My Account'))
                            }
                            toggle="false"
                        />

                        {this.isReferralOrCharityReferral() ? (
                            <MetisMenu
                                content={ReferalNav}
                                activeLinkFromLocation
                                className="app-sidebar__heading vertical-nav-menu"
                                style={{ marginLeft: '-10px' }}
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                                onSelected={
                                    (() =>
                                        Event(
                                            'MenuBar',
                                            'My Referrals',
                                            'LEFT_MENU'
                                        ),
                                    gtmEvent('My Account'))
                                }
                                toggle="false"
                                LinkComponent={(params) => (
                                    <LinkComponentWithNotificationFlag
                                        {...params}
                                        icon="pe-7s-share"
                                        flagCode={
                                            NotificationFlagsCategories.ReferralHubCode
                                        }></LinkComponentWithNotificationFlag>
                                )}
                            />
                        ) : (
                            []
                        )}
                    </div>
                ) : (
                    <></>
                )}
                {(this.isReferralOrCharityReferral() &&
                    !this.summary.charities) ||
                this.summary.charities?.length < 1 ? (
                    <MetisMenu
                        content={ReferralHomeNav}
                        activeLinkFromLocation
                        className="app-sidebar__heading vertical-nav-menu"
                        style={{ marginLeft: '-10px' }}
                        iconNamePrefix=""
                        classNameStateIcon="pe-7s-angle-down"
                        onSelected={
                            (() => Event('MenuBar', 'Home', 'LEFT_MENU'),
                            gtmEvent('Home'))
                        }
                        toggle="false"
                    />
                ) : (
                    []
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        axios: state.core.axios,
        summary: state.userSummary.summary,
    };
};

const mapDispatchToProps = (dispatch) => {
    dispatch(LoadUserSummaryFromStorage());
    return {
        loadSummary: dispatch(LoadUserSummaryFromStorage()),
    };
};

export default Helpers.withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Nav)
);
